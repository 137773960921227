<template>
  <div class="notify">
    <b-card>
      <!-- Other stuff -->
      <b-overlay :show="show">
        <b-form @submit="onSubmit">
          <b-row no-gutters>
            <b-col cols="12">
              <b-form-group
                label="Bật/Tắt"
                label-cols-md="2"
              >
                <b-form-checkbox
                  name="check-button"
                  v-model="donateSetting.json.status"
                  switch
                  inline
                  :value="true"
                  :unchecked-value="false"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-group
                label="Url donate"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-input
                  ref="mylink"
                  :value="url + donateSetting.token"
                  type="text"
                  rel="noopener noreferrer"
                  placeholder="Token donate alert address"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-button
                variant="danger"
                @click="generationURL"
              ><b-spinner
                v-if="loading_generate"
                small
              /> <feather-icon icon="RefreshCwIcon" size="12" />
              đổi url mới</b-button>
              <b-button
                variant="primary"
                @click="copyURL"
              ><feather-icon icon="CopyIcon" size="12" />
              Copy</b-button>
              <b-button
                variant="success"
                @click="testDonate"
              ><b-spinner
                v-if="loading_test"
                small
              /> <feather-icon icon="SendIcon" size="12" />
              Test</b-button>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-group
                label="Nội dung hiển thị"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-input
                  :disabled="donateSetting.json.message_template === null"
                  v-model="donateSetting.json.message_template"
                  type="text"
                  rel="noopener noreferrer"
                /> {name}: tên người gửi, {amount} số tiền, {currency} đơn vị tiền (vnd hoặc usd)
              </b-form-group>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-group
                label="Kiểu hiển thị"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-select v-model="donateSetting.json.template">
                  <b-form-select-option value="icon_top_text_bottom">Icon ở trên, chữ ở dưới</b-form-select-option>
                  <b-form-select-option value="icon_left_text_right">Icon ở bên trái, chữ ở bên phải</b-form-select-option>
                  <b-form-select-option value="icon_right_text_left">Icon ở bên phải, chữ ở bên trái</b-form-select-option>
                  <b-form-select-option value="icon_bottom_text_top">Icon ở dưới, chữ ở trên</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-group
                label="Lọc từ"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-tags
                  input-id="tags-basic"
                  v-model="donateSetting.json.str_filter"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-group
                label="Ảnh hiển thị"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-img
                  height="50px"
                  :src="donateSetting.json.icon_url"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-button
                class="mb-75 mr-75"
                v-b-modal.modal-1
                @click="changeTypeGallery('avatar')"
                accept=".jpg, .png, .gif"
              >
                <b-spinner small v-if="loading_upload_img"/>
                <feather-icon v-if="!loading_upload_img" icon="UploadIcon" size="12" />
                Tải lên</b-button>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-group
                label="Chuông"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="donateSetting.json.sound_url"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-button
                class="mb-75 mr-75"
                v-b-modal.modal-1
                @click="changeTypeGallery('sound')"
                accept=".mp3 .ogg"
              >
                <b-spinner small v-if="loading_upload_sound" />
                <feather-icon v-if="!loading_upload_sound" icon="UploadIcon" size="12" />
                Tải lên</b-button>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-group
                label="Chuông Volume"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <vue-slider
                  v-model="donateSetting.json.sound_volume"
                  :max="10"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-group
                label="Độ trễ thông báo"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <vue-slider
                  v-model="donateSetting.json.alert_delay"
                  :max="100"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="12">
              <b-form-group
                label="Bật GWU đọc"
                label-for="h-first-name"
                label-cols-md="2"
              >
                <b-form-checkbox
                  name="check-button"
                  v-model="donateSetting.json.google_speech"
                  switch
                  inline
                  :value="true"
                  :unchecked-value="false"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-group
                label="GWU đọc Volume"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <vue-slider
                  v-model="donateSetting.json.text_to_speech_volume"
                  :max="10"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-button
                type="submit"
                variant="success"
              >
                <b-spinner
                  small
                  v-if="loading_submit"
                ></b-spinner> Cập nhật</b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-overlay>
    </b-card>
    <ModalGalleries
      :galleries="galleries"
      :type_gallery="type_gallery"
      :loading_gallery="loading_gallery"
      @uploadfile="uploadFile"
    />
  </div>
</template>
<script>
import {
  BFormSelect, BFormSelectOption, BOverlay, BFormTags, BSpinner, BForm, BImg, BFormGroup, BFormInput, BCard, BRow, BCol, BButton, BFormCheckbox,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import VueSlider from 'vue-slider-component'

import useJwt from '@/auth/jwt/useJwt'

import ModalGalleries from './modals/Gallery.vue'

import 'vue-slider-component/theme/antd.css'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    BFormSelect,
    BFormSelectOption,
    BFormTags,
    BOverlay,
    ModalGalleries,
    VueSlider,
    BImg,
    BSpinner,
    BFormCheckbox,
    BButton,
    BFormGroup,
    BFormInput,
    BCard,
    BRow,
    BCol,
    BForm,
    FeatherIcon,
  },
  data() {
    return {
      show: false,
      url: `${process.env.VUE_APP_HOME_URL}/screens/donate/`,
      loading_test: false,
      file: null,
      type_gallery: 'avatar',
      galleries: [],
      loading_gallery: false,

      loading_upload_img: false,
      loading_upload_sound: false,

      loading_submit: false,
      loading_generate: false,
    }
  },
  directives: {
    Ripple,
  },
  created() {
    this.show = true
    if (this.donateSetting.id === null) {
      useJwt.getWidget('donate')
        .then(({ data }) => {
          this.$store.commit('donate/GET_DONATE', data)
          this.show = false
        }).catch(() => {
          // 404
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        })
    } else {
      this.show = false
    }
  },
  computed: {
    donateSetting() {
      return this.$store.getters['donate/donateData']
    },
  },
  methods: {
    onSubmit(e) {
      e.preventDefault()
      this.loading_submit = true
      useJwt.updateWidget('donate', this.donateSetting.json)
        .then(() => {
          this.$store.commit('donate/UPDATE_DONATE', this.donateSetting)
          this.loading_submit = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Update success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
    },
    uploadFile(files) {
      const formData = new FormData()
      formData.append('file', files)
      formData.append('id', this.donateSetting.id)
      if (this.type_gallery === 'avatar') {
        this.loading_upload_img = true
      } else {
        this.loading_upload_sound = true
      }
      useJwt.uploadDonateSetting(this.type_gallery,
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(({ data }) => {
          if (data.result === true) {
            if (this.type_gallery === 'avatar') {
              this.loading_upload_img = false
              this.donateSetting.json.icon_url = data.data
              this.$store.commit('donate/UPLOAD_IMG', data.data)
            } else {
              this.donateSetting.json.sound_url = data.data
              this.loading_upload_sound = false
              this.$store.commit('donate/UPLOAD_SOUND', data.data)
            }
            // thay đổi url
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Upload success!',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Upload failed!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
    changeTypeGallery(type) {
      this.type_gallery = type
      this.loading_gallery = true
    },
    testDonate() {
      this.loading_test = true
      useJwt.testDonate('donate', {
        ...this.donateSetting.json, id: this.donateSetting.id,
      })
        .then(res => {
          this.loading_test = false
          if(res.error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error!',
                icon: 'BellIcon',
                text: res.error,
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Test sended!',
                icon: 'BellIcon',
              },
            })
          }
        })
    },
    copyURL() {
      this.$refs.mylink.select()
      document.execCommand('copy')
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Text copied',
          icon: 'BellIcon',
        },
      })
    },
    generationURL(e) {
      e.preventDefault()
      this.loading_generate = true
      useJwt.generatorWidgetToken('donate').then(({ data }) => {
        this.loading_generate = false
        if (data.result === true) {
          this.$store.commit('donate/GENERATOR_TOKEN', {
            type: 'donate',
            payload: data.data,
          })
          // this.url = `${process.env.VUE_APP_HOME_URL}/screens/donate/${data.data}`
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Generated token success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: data.error,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
  },
}
</script>
