<template>
  <div>
    <!-- modal trigger button -->

    <!-- basic modal -->
    <b-modal
      size="lg"
      id="modal-1"
      title="Galleries"
      ok-only
      @ok="Submit"
      ok-title="Accept"
    >
      <b-tabs>
        <b-tab
          title="Stock"
        >
          <b-row>
            {{ loading_gallery === true ? 'Loading...' : '' }}
            <b-col
              v-for="gallery in galleries"
              v-show="!loading_gallery"
              v-bind:key="gallery.id"
              :cols="gallery.type == 1 ? 4 : 12"
            >
              <b-img
                v-if="gallery.type == 1"
                :src="gallery.url"
                height="50" />
              <audio
                v-if="gallery.type == 2"
                controls
              >
                <source
                  :src="gallery.url"
                  type="audio/ogg"
                >
                Your browser does not support the audio element.
              </audio>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab
          active
          title="Upload"
        >
          <b-form-file
            ref="file"
            v-model="file"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          />
        </b-tab>
      </b-tabs>
    </b-modal>

  </div>
</template>

<script>
import { BModal, VBModal, BFormFile, BTabs, BTab, BRow, BCol, BImg } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  methods: {
    Submit() {
      if (typeof this.$refs.file.files[0] !== 'undefined') {
        this.$emit('uploadfile', this.$refs.file.files[0])
      }
    },
  },
  data() {
    return {
      file: null,
      selected: null,
    }
  },
  props: ['galleries', 'type_gallery', 'loading_gallery'],
  components: {
    BImg,
    BModal,
    BTabs,
    BTab,
    BFormFile,
    BRow,
    BCol,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
}
</script>
